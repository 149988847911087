.lia-solution-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 8px;
  padding-right: 8px;

  &.lia-is-solution {
    background-color: var(--lia-forum-solved-color);

    .lia-solution-text {
      color: var(--lia-bs-white);
      font-size: var(--lia-font-size-xxs);
      font-weight: var(--lia-font-weight-md);
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .lia-close-icon {
      fill: var(--lia-bs-white);
    }

    &:hover {
      background-color: hsl(
        var(--lia-forum-solved-color-h),
        var(--lia-forum-solved-color-s),
        calc(var(--lia-forum-solved-color-l) * 0.85)
      );
    }
  }
}

.lia-solved-badge {
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-white);
  background-color: var(--lia-forum-solved-color);
  line-height: 1.454545454;
}

.lia-close-icon {
  height: 10px;
  width: 10px;
}
